$blue: #39b1ef;
$text1: #444;
$text2: #888;

.band {
	background: $blue;
	line-height: 2.5rem;
	font-size: 1.3em;
	height: 2.5rem;
	text-align: center;
	color: white;
	font-weight: 600;
	position: relative;
	.toggle {
		width: 50px;
		height: 100%;
		position: absolute;
		left: 0;
		cursor: pointer;
	}
}

.sidebar {
	h2 {
		font-size: 1.2em;
		font-weight: bold;
		width: 100%;
		border-bottom: 1px solid $blue;
		color: $text1;
		line-height: 2rem;
		margin: 0.5rem 0;
	}
	.band {
		margin-bottom: 1rem;
	}
}

.content {
	h2 {
		color: $blue;
		border-bottom: 2px solid $blue;
		line-height: 1.8em;
		font-size: 1.6em;
		margin-bottom: 0.5em;
	}
	h3 {
		font-weight: 400;
		border-bottom: 1px solid $blue;
		line-height: 1.6em;
		font-size: 1.2em;
		margin: 0;
	}
}

$toggle_h: 50px;
$line_h: $toggle_h/10;
$toggle_p: 2px;

.toggle {
	.line {
		left: 0;
		position: absolute;
		height: $line_h;
		width: 30px;
		background: white;
		&:nth-of-type(1) {
			top: $line_h * 1 + $toggle_p;
		}
		&:nth-of-type(2) {
			top: $line_h * 3 + $toggle_p;
		}
		&:nth-of-type(3) {
			top: $line_h * 5 + $toggle_p;
		}
	}
}

.skills {
	color: $text2;
	display: block;
	a:before {
		content: ">";
		left: -1em;
		position: absolute;
	}
	a {
		color: $text2;
		text-decoration: none;
		position: relative;
	}
}

a{
	color: $text1;
}
